import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Modal from "react-modal";
import Reklama from "./Reklama";
import SmallPoslednjiPozdrav from "./SmallPoslednjiPozdrav";
import MediumPPozdrav from "./MediumPPozdrav";
import PoslednjiPozdrav from "./PoslednjiPozdrav";
import MediumReklama from "./MediumReklama";
import TinyReklama from "./TinyReklama";
import QuarterPP from "./QuarterPP";
import { useHistory } from "react-router-dom";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import ReactDateRangePicker from "@wojtekmaj/react-daterange-picker";
import Select from "react-dropdown-select";
import { isSerbianCyrillic } from "../submitFormUtils/isSerbianCyrlic";
Modal.setAppElement("#root");
const limit = 10;

function PoslednjiPozdravi({
  ads,
  posts,
  cities,
  count,

  setDateCreated,
  dateCreated,
  offset,
  setOffset,
  selectedCity,
  setSelectedCity,
}) {
  const [selectedPost, setSelectedPost] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalSize, setModalSize] = useState(0);
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const [currentWritingSystem, setCurrentWritingSystem] = useState("latin");

  let userRole = localStorage.getItem("userRole");
  const today = new Date();

  // Get the current day, month, and year
  const currentDay = today.getDate();
  const currentMonth = today.getMonth() + 1; // Month is 0-indexed, so we add 1
  const currentYear = today.getFullYear();
  const history = useHistory();
  useEffect(() => {
    document.querySelector("#root>footer").style.display = modalIsOpen
      ? "none"
      : "block";
  }, [modalIsOpen]);
  const closeModal = () => {
    setSelectedPost(null);
    setModalType("");
    setModalSize(0);
    setModalIsOpen(false);
    history.goBack();
  };

  const openModal = (post, type, size) => {
    setSelectedPost(post);
    if (isSerbianCyrillic(post.name) === true) {
      setCurrentWritingSystem("cyrilic");
    } else {
      setCurrentWritingSystem("latin");
    }
    setModalType(type);
    setModalSize(size);
    setModalIsOpen(true);
    window.scrollTo({
      top: 0,
    });
    window.history.pushState(null, "", `/posts/${post.id}`);
  };

  window.onpopstate = () => {
    if (modalIsOpen === true) {
      setModalIsOpen(false);
    }
  };

  //   onClick={() => openModal(post, "poslednji_pozdrav", 3)}
  const handleNumberClick = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
  };

  const handleDecrementDay = () => {
    const currentDate = new Date();
    const dd = currentDate.getDate();
    const mm = currentDate.getMonth() + 1; // getMonth() is zero-based
    const yyyy = currentDate.getFullYear();
    const formattedDate = new Date(yyyy, mm - 1, dd - 1);
    if (!dateCreated) {
      const newDate = new Date(dateCreated);
      newDate.setDate(formattedDate.getDate() - 1);
      setDateCreated(formattedDate);
    } else {
      const newDate = new Date(dateCreated);
      newDate.setDate(dateCreated.getDate() - 1);
      setDateCreated(newDate);
    }
  };

  const handleIncrementDay = () => {
    const currentDate = new Date();
    const dd = currentDate.getDate();
    const mm = currentDate.getMonth() + 1; // getMonth() is zero-based
    const yyyy = currentDate.getFullYear();
    const formattedDate = new Date(yyyy, mm - 1, dd + 1);
    if (!dateCreated) {
      const newDate = new Date(dateCreated);
      newDate.setDate(formattedDate.getDate() + 1);
      setDateCreated(formattedDate);
    } else {
      const newDate = new Date(dateCreated);
      newDate.setDate(dateCreated.getDate() + 1);
      setDateCreated(newDate);
    }
  };
  const totalPages = Math.ceil(count / limit);

  const handlePreviousPage = () => {
    setOffset(offset - limit);
  };
  const handleUpdateButton = (id) => {
    window.location.href = `/posts/update/${id}`;
  };
  const LARGE = 3;
  const MEDIUM = 2;
  const SMALL = 1;
  const TINY = 4;
  const WHOLE = "WHOLE";
  const ROW = "ROW";
  const COLUMN = "COLUMN";
  const TINY_COLUMN = "TINY_COLUMN";

  function getPostOrder(POSTS) {
    let order = [];
    for (let i = 0; i < POSTS.length; i++) {
      order[i] = { post: null, type: WHOLE, size: LARGE };
    }

    POSTS.forEach((post) => {
      for (let i = 0; i < order.length; i++) {
        const slot = order[i];

        if (post.size === LARGE) {
          if (slot.type === WHOLE && slot.post === null) {
            order[i] = { post: post, type: WHOLE, size: LARGE };
            break;
          }
        } else if (post.size === MEDIUM) {
          if (slot.type === WHOLE && slot.post === null) {
            order[i] = {
              left: { post: post, type: WHOLE, size: MEDIUM },
              right: null,
              type: ROW,
            };
            break;
          } else if (slot.type === ROW) {
            if (slot.left === null) {
              order[i].left = { post: post, type: WHOLE, size: MEDIUM };
              break;
            }
            if (slot.right === null) {
              order[i].right = { post: post, type: WHOLE, size: MEDIUM };
              break;
            }
          }
        } else if (post.size === SMALL) {
          if (slot.type === WHOLE && slot.post === null) {
            order[i] = {
              left: {
                top: { post: post, type: WHOLE, size: SMALL },
                bottom: null,
                type: COLUMN,
              },
              right: null,
              type: ROW,
            };
            break;
          } else if (slot.type === ROW) {
            if (slot.left === null) {
              order[i].left = {
                top: { post: post, type: WHOLE, size: SMALL },
                bottom: null,
                type: COLUMN,
              };
              break;
            } else if (slot.left.type === COLUMN) {
              if (slot.left.top === null) {
                order[i].left.top = { post: post, type: WHOLE, size: SMALL };
                break;
              } else if (slot.left.bottom === null) {
                order[i].left.bottom = { post: post, type: WHOLE, size: SMALL };
                break;
              }
            }

            if (slot.right === null) {
              order[i].right = {
                top: { post: post, type: WHOLE, size: SMALL },
                bottom: null,
                type: COLUMN,
              };
              break;
            } else if (slot.right.type === COLUMN) {
              if (slot.right.top === null) {
                order[i].right.top = { post: post, type: WHOLE, size: SMALL };
                break;
              } else if (slot.right.bottom === null) {
                order[i].right.bottom = {
                  post: post,
                  type: WHOLE,
                  size: SMALL,
                };
                break;
              }
            }
          }
        } else if (post.size === TINY) {
          if (slot.type === WHOLE && slot.post === null) {
            order[i] = {
              left: {
                top: {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                },
                bottom: null,
                type: COLUMN,
              },
              right: null,
              type: ROW,
            };
            break;
          } else if (slot.type === ROW) {
            if (slot.left === null) {
              order[i].left = {
                top: {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                },
                bottom: null,
                type: COLUMN,
              };
              break;
            } else if (slot.left.type === COLUMN) {
              if (slot.left.top === null) {
                order[i].left.top = {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                };
                break;
              } else if (slot.left.top.type === TINY_COLUMN) {
                if (slot.left.top.top === null) {
                  order[i].left.top.top = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                } else if (slot.left.top.bottom === null) {
                  order[i].left.top.bottom = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                }
              }

              if (slot.left.bottom === null) {
                order[i].left.bottom = {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                };
                break;
              } else if (slot.left.bottom.type === TINY_COLUMN) {
                if (slot.left.bottom.top === null) {
                  order[i].left.bottom.top = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                } else if (slot.left.bottom.bottom === null) {
                  order[i].left.bottom.bottom = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                }
              }
            }

            if (slot.right === null) {
              order[i].right = {
                top: {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                },
                bottom: null,
                type: COLUMN,
              };
              break;
            } else if (slot.right.type === COLUMN) {
              if (slot.right.top === null) {
                order[i].right.top = {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                };
                break;
              } else if (slot.right.top.type === TINY_COLUMN) {
                if (slot.right.top.top === null) {
                  order[i].right.top.top = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                } else if (slot.right.top.bottom === null) {
                  order[i].right.top.bottom = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                }
              }

              if (slot.right.bottom === null) {
                order[i].right.bottom = {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                };
                break;
              } else if (slot.right.bottom.type === TINY_COLUMN) {
                if (slot.right.bottom.top === null) {
                  order[i].right.bottom.top = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                } else if (slot.right.bottom.bottom === null) {
                  order[i].right.bottom.bottom = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                }
              }
            }
          }
        }
      }
    });

    order.forEach((block, i) => {
      if (block.type === ROW) {
        if (block.left.type === COLUMN) {
          if (block.left.bottom && block.left.bottom.type === TINY_COLUMN) {
            const adRight = block.right === null;
            const adLeftBottomBottom = block.left.bottom.bottom === null;
            if (adRight && adLeftBottomBottom) {
              order[i].right = {
                type: COLUMN,
                top: {
                  type: TINY_COLUMN,
                  top: block.left.bottom.top,
                  bottom: null,
                },
                bottom: "hidden",
              };
              order[i].left.bottom.bottom = "hidden";
              order[i].left.bottom.top = "hidden";
            }
          } else if (block.left.top && block.left.top.type === TINY_COLUMN) {
            const adRight = block.right === null;
            const adLeftBottom = block.left.bottom === null;
            const adLeftTopBottom = block.left.top.bottom === null;
            if (adRight && adLeftBottom && adLeftTopBottom) {
              order[i].left.bottom = "hidden";
              order[i].left.top.bottom = "hidden";
              order[i].right = {
                type: COLUMN,
                top: {
                  type: TINY_COLUMN,
                  top: null,
                  bottom: "hidden",
                },
                bottom: "hidden",
              };
            }
          } else {
            const adRight = block.right === null;
            const adLeftBottom = block.left.bottom === null;
            if (adRight && adLeftBottom) {
              order[i].left.bottom = "hidden";
              order[i].right = {
                type: COLUMN,
                top: null,
                bottom: "hidden",
              };
            }
          }
        }
      }
    });
    return order;
  }

  function renderPostRow(post) {
    return (
      <div
        style={{
          paddingRight: "0",
          padding: "0",
          marginLeft: "0",
          marginRight: "0",
        }}
        key={0}
        className="row"
      >
        <div className="col-lg-6 col-sm-12 col-md-12">
          {renderPost(post.left, MEDIUM)}
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12">
          {renderPost(post.right, MEDIUM)}
        </div>
      </div>
    );
  }

  function renderPostColumn(post) {
    return (
      <div key={0} className="col">
        <div className="row-6">{renderPost(post.top, SMALL)}</div>
        <div className="row-6">{renderPost(post.bottom, SMALL)}</div>
      </div>
    );
  }

  function renderPostTinyColumn(post) {
    return (
      <div key={0} className="col">
        <div className="row-6">{renderPost(post.top, TINY)}</div>
        <div className="row-6">{renderPost(post.bottom, TINY)}</div>
      </div>
    );
  }

  function renderPostWhole(post) {
    if (post.post === null) {
      return;
    }

    if (post.size === LARGE) {
      return (
        <div
          key={post.post.id}
          style={{ paddingTop: "20px" }}
          onClick={() => openModal(post.post, "poslednji_pozdrav", 3)}
        >
          <PoslednjiPozdrav
            userRole={userRole}
            modalIsOpen={modalIsOpen}
            post={post.post}
          />
        </div>
      );
    } else if (post.size === MEDIUM) {
      return (
        <div
          key={post.post.id}
          style={{ marginTop: "20px" }}
          onClick={() => openModal(post.post, "poslednji_pozdrav", 2)}
        >
          <MediumPPozdrav
            userRole={userRole}
            modalIsOpen={modalIsOpen}
            post={post.post}
          />
        </div>
      );
    } else if (post.size === SMALL) {
      return (
        <div
          style={{ paddingTop: "20px" }}
          key={post.post.id}
          onClick={() => openModal(post.post, "poslednji_pozdrav", 1)}
        >
          <SmallPoslednjiPozdrav
            userRole={userRole}
            modalIsOpen={modalIsOpen}
            post={post.post}
          />
        </div>
      );
    } else if (post.size === TINY) {
      return (
        <div
          style={{ paddingTop: "20px" }}
          key={post.post.id}
          onClick={() => openModal(post.post, "poslednji_pozdrav", 4)}
        >
          <QuarterPP
            userRole={userRole}
            modalIsOpen={modalIsOpen}
            post={post.post}
          />
        </div>
      );
    } else {
      return <p>invalid post size</p>;
    }
  }
  function renderPost(post, size, index) {
    if (post === null || post === "hidden") {
      const displayStyle = {
        display: post === "hidden" ? "none" : "block",
      };
      if (size === MEDIUM) {
        return (
          <div key={index} style={displayStyle}>
            <MediumReklama ads={ads} />
          </div>
        );
      } else if (size === SMALL) {
        return (
          <div key={index} style={displayStyle}>
            <Reklama ads={ads} />
          </div>
        );
      } else if (size === TINY) {
        return (
          <div key={index} style={displayStyle}>
            <TinyReklama ads={ads} />
          </div>
        );
      }
    } else if (post.type === WHOLE) {
      return renderPostWhole(post, index);
    } else if (post.type === ROW) {
      return renderPostRow(post, index);
    } else if (post.type === COLUMN) {
      return renderPostColumn(post, index);
    } else if (post.type === TINY_COLUMN) {
      return renderPostTinyColumn(post, index);
    }
  }
  const exportPostAsImage = () => {
    window.open(`/posts/${selectedPost.id}?print=true`);
  };

  setTimeout(() => {
    setShouldShowLoader(false); // Set loader display to false after 500 ms
    // Trigger a re-render by updating the state (or any other method you use)
    // Note: You might want to use a more controlled state management for real apps.
  }, 500);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [offset]);

  return (
    <Container
      className="post-list"
      style={{
        paddingTop: "120px",
      }}
    >
      <div className="filters">
        <div className="filter-group">
          <label htmlFor="cityFilter">Izaberi grad</label>
          <Select
            style={{ marginTop: "8px" }}
            className="filter-select"
            placeholder={selectedCity.label}
            options={[
              { value: "", label: "Izaberi grad" },
              ...cities.map((city) => ({
                label: city.name,
                value: city.id,
              })),
            ]}
            onChange={(values) => {
              setSelectedCity(values[0]);
              if (values[0].value !== "") {
                localStorage.setItem(
                  "selectedCity",
                  `{"value":${values[0].value}, "label":"${values[0].label}"}`
                );
              } else {
                localStorage.setItem(
                  "selectedCity",
                  '{ "value": 0, "label": "Izaberi grad" }'
                );
              }
            }}
          />
        </div>
        <div className="filter-group">
          <label className="date-filter-label" htmlFor="dateFilter">
            Izaberi period
          </label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReactDateRangePicker
              name="death_date"
              onChange={setDateCreated}
              value={dateCreated}
              className="FormaZaPopunjavanje-input"
              style={{ marginTop: "8px" }}
              format="dd/MM/y"
              selectRange={true}
            />
          </div>
        </div>
      </div>
      {shouldShowLoader ? (
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "10px",
          }}
        >
          Očitavanje objava...
        </p>
      ) : (
        !modalIsOpen &&
        getPostOrder(posts).map((row, rowIndex) => {
          return row !== null && row.constructor !== Array ? (
            <div key={rowIndex} className="row">
              {renderPost(row, 1)}
            </div>
          ) : row !== null && row.length === 2 ? (
            row[0] !== null &&
            row[1] !== null &&
            row[0].size === 1 &&
            row[1].size === 1 ? (
              <div key={rowIndex} className="row">
                <div className="col-lg-6 col-sm-12 col-md-12">
                  {renderPost(row[0], 2)} {renderPost(row[1], 2)}
                </div>
                <div className="col-lg-6 col-sm-12 col-md-12"></div>
              </div>
            ) : (
              <div key={rowIndex} className="row">
                <div className="col-lg-6 col-sm-12 col-md-12">
                  {renderPost(row[0], 2)}
                </div>
                <div className="col-lg-6 col-sm-12 col-md-12">
                  {renderPost(row[1], 2)}
                </div>
              </div>
            )
          ) : row !== null && row.length === 3 ? (
            row[0] !== null && row[0].size === 2 ? (
              <div key={rowIndex} className="row">
                <div className="col-lg-6 col-sm-12 col-md-12">
                  {renderPost(row[0], 3)}
                </div>
                <div className="col-lg-6 col-sm-12 col-md-12">
                  {renderPost(row[1], 3)} {renderPost(row[2], 3)}
                </div>
              </div>
            ) : (
              <div key={rowIndex} className="row">
                <div className="col-lg-6 col-sm-12 col-md-12">
                  {renderPost(row[0], 3)} {renderPost(row[1], 3)}
                </div>
                <div className="col-lg-6 col-sm-12 col-md-12">
                  {renderPost(row[2], 3)}
                </div>
              </div>
            )
          ) : row !== null && row.length === 2 ? (
            <div key={rowIndex} className="row">
              <div className="col-lg-6 col-sm-12 col-md-12">
                {renderPost(row[0], 4)} {renderPost(row[1], 4)}
              </div>
              <div className="col-lg-6 col-sm-12 col-md-12">
                {renderPost(row[2], 4)} {renderPost(row[3], 4)}
              </div>
            </div>
          ) : (
            <div key={rowIndex} className="row prazno"></div>
          );
        })
      )}
      {posts.length === 0 && (
        <div
          style={{
            minHeight: "450px",
            paddingTop: "10px",
            textAlign: "center",
          }}
        >
          Nema objava za izabranu pretragu
        </div>
      )}
      {!shouldShowLoader && (
        <div className="pagination">
          {offset !== 0 && (
            <button
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "8px",
              }}
              onClick={handlePreviousPage}
              disabled={offset === 0} // Disable on first page
            >
              Prethodna
            </button>
          )}
          {/* Render clickable numbers for pages */}
          {totalPages > 1 && (
            <div>
              {Array.from({ length: totalPages }).map((_, index) => {
                if (index <= 4 || index === totalPages - 1) {
                  return (
                    <button
                      style={{
                        backgroundColor:
                          index * limit === offset ? "gray" : "black",
                        color: "white",
                        borderRadius: "8px",
                      }}
                      key={index}
                      onClick={() => setOffset(index * limit)}
                      disabled={index * limit === offset}
                    >
                      {index + 1}
                    </button>
                  );
                } else if (index === 5) {
                  return <span key={index}>...</span>;
                } else {
                  return null;
                }
              })}

              {offset + limit < count && (
                <button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "8px",
                  }}
                  onClick={() => setOffset(offset + limit)}
                >
                  Sledeća
                </button>
              )}
            </div>
          )}
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentlabel="Post Modal"
        size="lg"
        style={{ padding: "0px" }}
      >
        <Container
          className="modal-mobile-view"
          style={{
            paddingLeft: `${selectedPost?.size === 3 ? "7%" : "10%"}`,
            paddingRight: `${selectedPost?.size === 3 ? "7%" : "10%"}`,
          }}
        >
          <Row style={{ margin: "0" }}>
            {selectedPost &&
              modalType === "poslednji_pozdrav" &&
              modalSize === 3 && (
                <PoslednjiPozdrav
                  modalIsOpen={modalIsOpen}
                  post={selectedPost}
                />
              )}

            {selectedPost &&
              modalType === "poslednji_pozdrav" &&
              modalSize === 2 && (
                <MediumPPozdrav modalIsOpen={modalIsOpen} post={selectedPost} />
              )}

            {selectedPost &&
              modalType === "poslednji_pozdrav" &&
              modalSize === 1 && (
                <SmallPoslednjiPozdrav
                  modalIsOpen={modalIsOpen}
                  post={selectedPost}
                />
              )}
            {selectedPost &&
              modalType === "poslednji_pozdrav" &&
              modalSize === 4 && (
                <QuarterPP modalIsOpen={modalIsOpen} post={selectedPost} />
              )}
          </Row>
          <div>
            {(userRole === "superadmin" || userRole === "admin") && (
              <button
                style={{
                  marginTop: "2%",
                  padding: "5px",
                  backgroundColor: "transparent",
                  marginRight: "1%",
                  color: "black",
                  border: "2px solid black",
                  borderRadius: "8px",
                }}
                onClick={() => handleUpdateButton(selectedPost.id)}
              >
                {currentWritingSystem === "cyrilic"
                  ? "Уреди објаву"
                  : "Uredi objavu"}
              </button>
            )}

            <button
              className="stampaj-button"
              style={{
                marginTop: "2%",
                padding: "5px",
                backgroundColor: "transparent",
                color: "wblack",
                borderRadius: "8px",

                border: "2px solid black",
              }}
              onClick={() => exportPostAsImage(`post-${selectedPost.id}`)}
            >
              {currentWritingSystem === "cyrilic" ? "Штампај" : "Štampaj"}
            </button>
            <button
              style={{
                float: "right",
                borderRadius: "8px",
                marginTop: "2%",
                padding: "5px",
                backgroundColor: "black",
                color: "white",
                border: "2px solid black",
              }}
              onClick={closeModal}
            >
              {currentWritingSystem === "cyrilic" ? "Затвори" : "Zatvori"}
            </button>
          </div>
        </Container>
      </Modal>
    </Container>
  );
}

export default PoslednjiPozdravi;
