import * as Yup from "yup";
export const validationSchemaBuilder = (formType, formSize, cities) => {
  let baseSchema = {
    city: Yup.string()
      .notOneOf(["Izaberi grad"], "Grad je obavezan")
      .required("Grad je obavezan"),
    name: Yup.string()
      .required("Ime i prezime je obavezno")
      .matches(
        /^[a-zA-ZščćđžŠČĆĐŽ\u0400-\u04FF\u0110\u0111\s-]+$/,
        "Ime i prezime ne smije da sadrži brojeve i specijalne karaktere"
      ),
    main_text: Yup.string().required("Glavni tekst je obavezan"),
    bottom_text: Yup.string().required("Donji tekst je obavezan"),
    phone_number: Yup.string()
      .required("Broj telefona je obavezan")
      .matches(/[0-9]/, "Broj telefona može da sadrži samo brojeve"),
  };

  if (formType === "Pomen") {
    // PRILAGODITI OBJAVAMA VALIFDACIJU
    if (formSize === 1) {
      baseSchema.main_text = undefined;
      baseSchema.text_top = undefined;
    }
    if (formSize === 4) {
      baseSchema.bottom_text = undefined;
      baseSchema.text_top = undefined;
    }
  }

  if (formType === "Umrlica") {
    // PRILAGODITI OBJAVAMA VALIFDACIJU
    if (formSize === 2) {
      baseSchema.header_text = undefined;
    }
    if (formSize === 1) {
      baseSchema.text_top = undefined;
    }
    if (formSize === 4) {
      baseSchema.text_top = undefined;
      baseSchema.main_text = undefined;
      baseSchema.bottom_text = undefined;
    }
  }
  if (formType === "Posljednji pozdrav") {
    if (formSize === 4) {
      baseSchema.text_top = undefined;
      baseSchema.main_text = undefined;
      baseSchema.bottom_text = undefined;
    }
  }
  return Yup.object(baseSchema);
};
