import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { API_URL } from "../config";

const EditPrices = () => {
  const [newPrices, setNewPrices] = useState([]);
  const [showModal, setShowModal] = useState(false); // Controls modal visibility
  const [selectedPrice, setSelectedPrice] = useState(null);

  // Fetch prices from the API
  function getPrices() {
    const apiUrl = API_URL + "/api/prices/";
    const accessToken = localStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setNewPrices(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  // Update the price
  function updatePrice() {
    if (!selectedPrice) return;
    const { id, newPriceSingle, newPriceMultiple, type, size } = selectedPrice;
    const apiUrl = `${API_URL}/api/prices/${id}/`;
    const accessToken = localStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    axios
      .put(
        apiUrl,
        {
          type: type,
          size: size,
          price_single: newPriceSingle,
          price_multiple: newPriceMultiple,
        },
        { headers }
      )
      .then((response) => {
        NotificationManager.success("Cijena uspješno izmijenjena");
        setShowModal(false); // Close modal after saving
        getPrices(); // Refresh prices
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    getPrices();
  }, []);

  // Handle price change in input fields
  const handlePriceChange = (id, newValue, field) => {
    setNewPrices((prevPrices) =>
      prevPrices.map((price) =>
        price.id === id ? { ...price, [field]: newValue } : price
      )
    );
  };

  // Open modal and store the selected price
  const openModal = (price) => {
    setSelectedPrice({
      id: price.id,
      newPriceSingle: parseFloat(price.price_single),
      newPriceMultiple: parseFloat(price.price_multiple),
      type: price.type,
      size: price.size,
    });
    setShowModal(true); // Show modal
  };

  // Close the modal without saving
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: "center", marginTop: "1rem" }}>Uredi cijene</h3>
      <Row className="justify-content-center">
        {newPrices.map((price, index) => (
          <Card
            key={index}
            lg={3}
            style={{ width: "20rem", marginTop: "2rem", padding: "1rem" }}
            className="shadow-sm"
          >
            <Card.Body>
              <Card.Title
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                {price.type === "umrlica"
                  ? "Umrlica"
                  : price.type === "poslednji_pozdrav"
                  ? "Posljednji pozdrav"
                  : "Pomen"}
              </Card.Title>

              <Card.Subtitle
                style={{
                  textAlign: "center",
                  fontSize: "0.9rem",
                  marginBottom: "1rem",
                }}
                className="text-muted"
              >
                {(() => {
                  switch (price.size) {
                    case 1:
                      return "Mali";
                    case 2:
                      return "Srednji";
                    case 3:
                      return "Veliki";
                    case 4:
                      return "Najmanji";
                    default:
                      return "Unknown Size";
                  }
                })()}
              </Card.Subtitle>

              {price.size === 5 && price.type == "umrlica" ? (
                <div style={{ textAlign: "center" }}>
                  Ova veličina ne postoji
                </div>
              ) : (
                <>
                  {/* Field for single image price */}
                  <div style={{ marginBottom: "1rem" }}>
                    <label htmlFor={`price_single_${price.id}`}>
                      Cijena sa jednom fotografijom
                    </label>
                    <input
                      id={`price_single_${price.id}`}
                      placeholder="Cijena sa jednom fotografijom"
                      type="number"
                      value={price.price_single} // Pre-fill the value
                      onChange={(e) =>
                        handlePriceChange(
                          price.id,
                          e.target.value,
                          "price_single"
                        )
                      }
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        padding: "0.5rem",
                      }}
                    />
                  </div>

                  {/* Field for multiple images price */}
                  {price.size !== 4 && (
                    <div style={{ marginBottom: "1rem" }}>
                      <label htmlFor={`price_multiple_${price.id}`}>
                        Cijena sa više fotografija
                      </label>
                      <input
                        id={`price_multiple_${price.id}`}
                        placeholder="Cijena sa više fotografija"
                        type="number"
                        value={price.price_multiple} // Pre-fill the value
                        onChange={(e) =>
                          handlePriceChange(
                            price.id,
                            e.target.value,
                            "price_multiple"
                          )
                        }
                        style={{
                          width: "100%",
                          borderRadius: "8px",
                          padding: "0.5rem",
                        }}
                      />
                    </div>
                  )}

                  <Button
                    variant="primary"
                    style={{ width: "100%", marginTop: "1rem" }}
                    onClick={() => openModal(price)} // Open modal on click
                  >
                    Sačuvaj
                  </Button>
                </>
              )}
            </Card.Body>
          </Card>
        ))}
        <NotificationContainer animation={false} />
      </Row>

      {/* Bootstrap Modal for confirmation */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Potvrda promjene</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Da li ste sigurni da želite promijeniti cijenu?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Otkaži
          </Button>
          <Button variant="primary" onClick={updatePrice}>
            Da, Sačuvaj
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditPrices;
