import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { shortenString } from "../utils";
import { API_URL } from "../config";

const SubmitFormPreview = ({
  isSlider,
  multipleImages,
  type,
  size,
  name,
  color,
  icon,
  image,
  main_text,
  bottom_text,
  headerText,
  birthDate,
  deathDate,
  text_top,
}) => {
  let modalStyle = {
    backgroundColor: "white",
    height: "auto",
    padding: "0",
    width: "100%",
    border: `5px solid ${
      color && color === "blue"
        ? "blue" // Use the specific color for "blue"
        : color === "green" || color === "black" || color === "red"
        ? color // Use other colors for "green," "black," or "red"
        : "black" // Default color for other cases
    }`,
  };

  if (type === "Umrlica") {
    modalStyle.border = `5px solid ${
      color && color === "blue"
        ? "blue" // Use the specific color for "blue"
        : color === "green" || color === "black" || color === "red"
        ? color // Use other colors for "green," "black," or "red"
        : "black" // Default color for other cases
    }`;
  } else if (type === "Pomen") {
    modalStyle.border = "7px double black";
  } else if (type === "Posljednji pozdrav") {
    modalStyle.border = "3px solid black";
  }

  // Use a conditional statement to set the font size based on the size
  if (size === "Najmanja") {
    modalStyle.fontSize = "14px";
  } else if (size === "Mala") {
    modalStyle.fontSize = "16px";
  } else if (size === "Srednja") {
    modalStyle.fontSize = "16px";
  } else if (size === "Velika") {
    modalStyle.fontSize = "17px";
  }

  if (size === "Najmanja") {
    modalStyle.maxWidth = "520px";
  } else if (size === "Mala") {
    modalStyle.maxWidth = "520px";
  } else if (size === "Srednja") {
    modalStyle.maxWidth = "520px";
    modalStyle.minHeight = "650px";
  } else if (size === "Velika") {
    modalStyle.maxWidth = "100%";
    modalStyle.minHeight = "650px";
  }

  return (
    <Container className="post" style={modalStyle} lg={6} sm={12} md={12}>
      <Row
        style={{
          fontStyle: "italic",
          border: "none",
          borderTop: "none",
          display: "flex",
          justifyContent: "end",

          paddingRight: "2vh",
          backgroundColor: "transparent",
          color: "gray",
          fontSize: "smaller",
        }}
      >
        {type}
      </Row>{" "}
      {(size === "Velika" || size === "Srednja") &&
        (type === "Umrlica" ||
          type == "Pomen" ||
          type === "Posljednji pozdrav") && (
          <Col
            style={{ marginTop: "30px" }}
            className="d-flex justify-content-center"
          >
            {icon !== "Bez ikonice" && (
              <img
                id="umrlica-icon"
                style={{
                  height: "60px",
                  width: "60px",
                  border: "none",
                  marginBottom: "1%",
                }}
                src={icon}
                alt=""
              />
            )}
          </Col>
        )}
      {size === "Srednja" && type === "Posljednji pozdrav" && (
        <p style={{ textAlign: "center", paddingTop: "1%", paddingLeft: "3%" }}>
          {text_top}
        </p>
      )}
      {type === "Pomen" && size === "Srednja" && (
        <div style={{ marginTop: "60px" }}></div>
      )}
      {size === "Srednja" && type === "Pomen" && (
        <p style={{ textAlign: "center", paddingTop: "1%", paddingLeft: "3%" }}>
          {text_top}
        </p>
      )}
      {size === "Srednja" && type === "Umrlica" && (
        <p style={{ textAlign: "center", paddingTop: "1%", paddingLeft: "3%" }}>
          {text_top}
        </p>
      )}
      {size === "Mala" && type === "Posljednji pozdrav" && (
        <p style={{ textAlign: "center", paddingTop: "1%" }}>{text_top}</p>
      )}
      {size === "Velika" && (
        <p style={{ textAlign: "center", paddingTop: "1%" }}>{text_top}</p>
      )}
      {type === "Pomen" && size !== "Najmanja" && (
        <p style={{ textAlign: "center", color: "gray", paddingTop: "1%" }}>
          {headerText}
        </p>
      )}{" "}
      {multipleImages === "false" && type !== "Pomen" && (
        <Row>
          <Col className="d-flex justify-content-around"></Col>
          {size !== "Najmanja" &&
            (type !== "Pomen" || type !== "Posljednji pozdrav") && (
              <Col className="d-flex justify-content-around">
                <img
                  style={{
                    marginTop: "5%",
                    width: "110px",
                    height: "130px",
                    border: "3px solid black",
                  }}
                  src={`${API_URL}/` + image}
                />{" "}
              </Col>
            )}
          <Col className="d-flex justify-content-around"></Col>
        </Row>
      )}
      {multipleImages === "true" && type !== "Pomen" && isSlider === true && (
        <Row>
          <Col className="d-flex justify-content-around">
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
              style={{ height: "fit-content" }}
            >
              {image?.map((imagePath, index) => (
                <div className="d-flex justify-content-center" key={index}>
                  <div
                    style={{
                      width: "110px",
                      height: "130px",
                      border: "3px solid black",
                      background: `url(${API_URL}/${imagePath})`,
                      backgroundSize: "cover",
                      backgroundPosition: "50% 50%",
                    }}
                  ></div>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      )}
      {isSlider === true && multipleImages === true && (
        <Row style={{ paddingTop: "0%" }}>
          <Col className="d-flex justify-content-around"></Col>{" "}
          <Col className="d-flex justify-content-around">
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
            >
              {image?.map((imagePath, index) => (
                <div className="d-flex justify-content-center" key={index}>
                  <div
                    style={{
                      width: "120px",
                      height: "150px",
                      border: "3px solid black",
                      background: `url(${API_URL}/${imagePath})`,
                      backgroundSize: "cover",
                      backgroundPosition: "50% 50%",
                    }}
                  ></div>
                </div>
              ))}
            </Carousel>
          </Col>
          <Col className="d-flex justify-content-around"></Col>
        </Row>
      )}
      {isSlider === false && multipleImages === true && (
        <Row>
          {image && (
            <>
              <Col className="d-flex justify-content-around">
                <div
                  style={{
                    width: "120px",
                    height: "150px",
                    border: "3px solid black",
                    background: `url(${API_URL}/${image[0]})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                  }}
                  src={``}
                  alt=""
                ></div>
              </Col>
              <Col className="d-flex justify-content-around">
                <div
                  style={{
                    width: "120px",
                    height: "150px",
                    border: "3px solid black",
                    background: `url(${API_URL}/${image[1]})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                  }}
                  src={``}
                  alt=""
                ></div>
              </Col>
            </>
          )}
        </Row>
      )}
      {isSlider === false && multipleImages === false && (
        <Row>
          {image && (
            <Col className="d-flex justify-content-around">
              <div className="d-flex justify-content-center">
                <img
                  style={{
                    width: "120px",
                    height: "150px",
                    border: "3px solid black",
                    background: `url(${API_URL}/${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                  }}
                  src={API_URL + "/" + image}
                ></img>
              </div>
            </Col>
          )}
        </Row>
      )}
      <Row style={{ paddingTop: "1%" }}>
        <Col
          style={{
            fontWeight: "bold",
            letterSpacing: "1.2px",
            textAlign: "center",
          }}
          className="d-flex justify-content-around"
        >
          {name.includes("\n")
            ? name
                .split("\n")
                .slice(0, 2)
                .map((line, index, array) => (
                  <React.Fragment key={index}>
                    {shortenString(line, 150)}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))
            : name}
        </Col>
      </Row>
      {type !== "Umrlica" && size !== "Najmanja" && (
        <Col
          style={{ marginTop: "20px" }}
          className="d-flex justify-content-center"
        ></Col>
      )}
      {type === "Umrlica" && size !== "Najmanja" && (
        <Col className="d-flex justify-content-around">
          <span style={{ fontSize: "small", paddingBottom: "3%" }}>
            {birthDate} - {deathDate}
          </span>
        </Col>
      )}
      {size === "Srednja" && (
        <Row>
          {type === "Umrlica" && (
            <p
              style={{
                padding: "3%",
                textAlign: "left",
                paddingLeft: "5%",
                height: "auto",
                overflow: "hidden",
                wordWrap: "break-word", // This allows long words to be broken and wrap to the next line
                whiteSpace: "normal", // This prevents the text from overflowing the box
              }}
            >
              {shortenString(main_text, 150)}
            </p>
          )}
          {type === "Pomen" && (
            <p
              style={{
                padding: "3%",
                textAlign: "center",
                paddingLeft: "5%",
                height: "auto",
                overflow: "hidden",
                wordWrap: "break-word", // This allows long words to be broken and wrap to the next line
                whiteSpace: "normal", // This prevents the text from overflowing the box
              }}
            >
              {shortenString(main_text, 150)}
            </p>
          )}
          {type === "Posljednji pozdrav" && (
            <p
              style={{
                padding: "3%",
                textAlign: "left",
                paddingLeft: "5%",
                height: "auto",
                overflow: "hidden",
                wordWrap: "break-word", // This allows long words to be broken and wrap to the next line
                whiteSpace: "normal", // This prevents the text from overflowing the box
              }}
            >
              {shortenString(main_text, 150)}
            </p>
          )}
        </Row>
      )}
      {size === "Velika" && (
        <Row>
          {type === "Umrlica" && (
            <p style={{ padding: "3%", paddingLeft: "5%" }}>
              {" "}
              {shortenString(main_text, 200)}
            </p>
          )}
          {type === "Pomen" && (
            <p style={{ padding: "3%", paddingLeft: "5%" }}>
              {" "}
              {shortenString(main_text, 200)}
            </p>
          )}
          {type === "Posljednji pozdrav" && (
            <p
              style={{ padding: "3%", textAlign: "center", paddingLeft: "5%" }}
            >
              {shortenString(main_text, 200)}
            </p>
          )}
        </Row>
      )}
      {size === "Najmanja" && (
        <Row>
          {type === "Umrlica" && (
            <p style={{ textAlign: "center" }}>
              {" "}
              {shortenString(main_text, 80)}
            </p>
          )}
          {type === "Pomen" && (
            <p style={{ textAlign: "center" }}>
              {" "}
              {shortenString(main_text, 80)}
            </p>
          )}
          {type === "Posljednji pozdrav" && (
            <p
              style={{ padding: "3%", textAlign: "center", paddingLeft: "5%" }}
            >
              {shortenString(main_text, 80)}
            </p>
          )}
        </Row>
      )}
      {size === "Srednja" && (
        <Row>
          {type === "Umrlica" && (
            <div>
              <hr></hr>
              <h5 style={{ paddingLeft: "3%", fontSize: "small" }} id="sakrij">
                Ožalošćeni:
              </h5>
            </div>
          )}
          {type === "Umrlica" && (
            <p style={{ paddingLeft: "5%", fontWeight: "bold" }} id="sakrij">
              {shortenString(bottom_text, 150)}
            </p>
          )}
          {type === "Pomen" && (
            <p style={{ paddingLeft: "5%", fontWeight: "bold" }} id="sakrij">
              {shortenString(bottom_text, 150)}
            </p>
          )}
          {type === "Posljednji pozdrav" && (
            <p
              style={{
                paddingRight: "5%",
                paddingLeft: "5%",
                paddingTop: "10%",
                fontWeight: "bold",
              }}
              id="sakrij"
            >
              {shortenString(bottom_text, 150)}
            </p>
          )}
        </Row>
      )}{" "}
      {size === "Velika" && (
        <Row>
          {type === "Umrlica" && (
            <Row style={{ padding: "2%", paddingLeft: "0" }}>
              <b>
                <h5 style={{ paddingLeft: "5%" }}>Ožalošćeni:</h5>
                <p style={{ paddingLeft: "5%" }}>
                  {" "}
                  {shortenString(bottom_text, 200)}
                </p>
              </b>
            </Row>
          )}
          {type === "Pomen" && (
            <p
              style={{
                textAlign: "right",
                fontWeight: "bold",
                paddingRight: "5%",
              }}
              id="sakrij"
            >
              {shortenString(bottom_text, 200)}
            </p>
          )}

          {type === "Posljednji pozdrav" && (
            <p style={{ textAlign: "right", paddingRight: "5%" }} id="sakrij">
              {shortenString(bottom_text, 200)}
            </p>
          )}
        </Row>
      )}
      {type === "Umrlica" && size === "Mala" && (
        <div>
          <Row style={{ padding: "2%", paddingLeft: "0" }}>
            <b>
              <p style={{ marginLeft: "5%", fontSize: "small" }}>
                {" "}
                {shortenString(main_text, 100)}
              </p>
            </b>
          </Row>
          <Row style={{ padding: "2%", paddingLeft: "0" }}>
            <b>
              <span style={{ fontWeight: "normal", marginLeft: "5%" }}>
                Ožalošćeni:
              </span>
              <p style={{ marginLeft: "5%", fontSize: "small" }}>
                {shortenString(bottom_text, 100)}
              </p>
            </b>
          </Row>
        </div>
      )}
      {type === "Posljednji pozdrav" && size === "Mala" && (
        <div>
          <Row style={{ padding: "2%", paddingLeft: "0" }}>
            <b>
              <p style={{ marginLeft: "5%", fontSize: "small" }}>
                {" "}
                {shortenString(main_text, 100)}
              </p>
            </b>
          </Row>

          <Row style={{ padding: "2%", paddingLeft: "5%" }}>
            <hr style={{ margin: "0", marginBottom: "1%" }}></hr>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              Ožalošćeni {"..."}
            </span>

            <p style={{ fontWeight: "bold", paddingTop: "1%" }}>
              {shortenString(bottom_text, 100)}
            </p>
          </Row>
        </div>
      )}
      {type === "Pomen" && size === "Mala" && (
        <div>
          <p
            style={{
              fontWeight: "bold",
              paddingTop: "1%",
              textAlign: "right",
              fontSize: "small",
              paddingLeft: "1%",
              paddingRight: "2%",
            }}
          >
            {shortenString(bottom_text, 100)}
          </p>
        </div>
      )}
    </Container>
  );
};

export default SubmitFormPreview;
