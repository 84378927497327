import React, { useState } from "react";
import { Col, Table, Button } from "react-bootstrap";
import { API_URL } from "../config";
import * as XLSX from "xlsx";

function Users({ regularUsers, fetchUsers }) {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const deleteUser = (userId) => {
    const accessToken = localStorage.getItem("accessToken");

    // Send a DELETE request to the API to delete the user
    fetch(`${API_URL}/api/regular/${userId}/delete/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          // Fetch the updated user list
          fetchUsers();
        } else {
          console.error("Failed to delete user:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const filteredUsers = regularUsers.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const exportToExcel = () => {
    // Create a new array of users without the unwanted properties
    const usersToExport = filteredUsers.map(
      ({ id, is_active, type, ...rest }) => rest
    );
    const ws = XLSX.utils.json_to_sheet(usersToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, "Users.xlsx");
  };

  return (
    <Col style={{ paddingTop: "20px", width: "100%", height: "100%" }} lg={6}>
      <div style={{ overflowY: "auto", maxHeight: "400px" }}>
        <p>Ukupan broj korisnika: {filteredUsers.length}</p>
        <input
          style={{
            borderRadius: "8px",
            backgroundColor: "black",
            color: "white",
            marginBottom: "10px",
          }}
          type="text"
          value={searchValue}
          onChange={handleSearchChange}
          placeholder="Pretraži korisnike..."
        />
        <Button
          onClick={exportToExcel}
          style={{ marginBottom: "10px", float: "right" }}
        >
          Preuzmi excel
        </Button>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Korisnici</th>
              <th>Poslednji login</th>
              <th>Datum registracije</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>
                  {user.first_name} {user.last_name}
                  <button
                    style={{
                      backgroundColor: "white",
                      opacity: "0.5",
                      padding: "2px",
                      border: "1px solid white",
                      float: "right",
                      marginRight: "10px",
                      borderRadius: "8px",
                    }}
                    onClick={() => deleteUser(user.id)}
                  >
                    <img
                      src={require("../assets/user-x-solid-24.png")}
                      alt="Delete"
                    />
                  </button>
                </td>
                <td>
                  {user.last_login
                    ? new Date(user.last_login).toLocaleString()
                    : "/"}
                </td>
                <td>{new Date(user.date_joined).toLocaleDateString()}</td>
                <td>{user.email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
}

export default Users;
